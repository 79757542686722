<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                             <v-row col="12">
                                 <v-row align="start" justify="start" class="mt-4">

                                     <v-col class="d-flex" cols="12">
                                         <v-autocomplete v-model="serverParams.AirportName"
                                                         :items="AirportList"
                                                         :label="$t('report_type')"
                                                         clearable
                                                         dense
                                                         outlined
                                                         single-line
                                                         item-text="value"
                                                         item-value="value"
                                         ></v-autocomplete>
                                     </v-col>
                                     <v-col class="d-flex" cols="12" sm="12">
                                         <v-spacer></v-spacer>
                                         <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                             <v-icon dark> search</v-icon>
                                             {{$t('search')}}
                                         </v-btn>
                                         <v-btn @click.stop="onDownload" small class="success mx-4">
                                             <v-icon>get_app</v-icon>
                                             {{$t('download_to_excel')}}
                                         </v-btn>
                                         <v-spacer></v-spacer>
                                     </v-col>

                                 </v-row>
                             </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-simple-table class="myCustomTable">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <td v-for="header in columns" :key="header.field">
                            {{header.label}}
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(items,index) in rows" :key="'key'+index">
                        <td v-for="(item,key) in items" :key="key">
                            {{item}}
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('airport_report'),
                        exact: true,
                        to: '/reportModules/reportAirport'
                    },
                    {
                        text: this.$t('aviation_reports'),
                        exact: true,
                        disabled: true
                    }
                ]
            },
            columns() {
                return [
                    {
                        label: this.$t('s_no'),
                        field: 'countIndex',
                    },
                    {
                        label: this.$t('airport_name'),
                        field: 'airportName',
                    },
                    {
                        label: this.$t('table.iata'),
                        field: 'iataCode',
                    },
                    {
                        label: this.$t('table.icao'),
                        field: 'icaoCode',
                    },
                    {
                        label: this.$t('table.WAC'),
                        field: 'worldAreaCode',
                    },
                    {
                        label: this.$t('table.airport_type'),
                        field: 'airportType',
                    },
                    {
                        label: this.$t('table.doe'),
                        field: 'establishedYear',
                    },
                    {
                        label: this.$t('table.length_of_runway'),
                        field: 'lengthOfRunway',
                    },
                    {
                        label: this.$t('no_of_building'),
                        field: 'numberOfBuilding',
                    },
                    {
                        label: this.$t('table.address'),
                        field: 'address',
                    }
                ]
            }
        },
        methods: {

            loadFilterData(){
              axios.get('Airport/DDLAirportListAsync').then(response=>{
                        this.AirportList = response.data
                        this.AirportList.push({id: "All", value: "All"})
              }).catch(err=>{
                  console.log(err)
              })
            },
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            formResponse(data) {
                console.log({data})
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {
                let param = {
                    AirportName: this.serverParams.AirportName
                };
                await axios({
                    url: 'Airport/DownloadAirPortreportListAsyc',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'AirPortreportListAsyc.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                });
                //await axios({
                //    url: 'Report/DownloadSample',
                //    method: 'POST',
                //    responseType: 'blob'
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'NationalParkFile.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },

            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    AirportName: this.serverParams.AirportName
                };
                axios.post('Airport/GetAirPortreportListAsyc', param)
                    .then(response => {
                        this.rows = response.data
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.loadItems()
            this.loadFilterData()
        },
        data() {
            return {
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                rows: [],
                AirportList: [],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    AirportName:"",
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    $blue: #265b93;
    .myCustomTable {
        max-width: 100%;

        thead {
            background-color: $blue;
            color: white;
        }

        tbody {
            height: 100px; /* Just for the demo          */
            overflow-y: auto; /* Trigger vertical scroll    */
        }

        td, th {
            padding: 1rem
        }

        border: 1px solid #eee;

        tr, td, th {
            border: 1px solid #eee;
        }
    }

    .table-container {
        width: 100%;
    }

    .headerBackground {
        background-color: $blue;
        color: white;
    }
</style>
